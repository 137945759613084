var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "orderTest-container",
    },
    [
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    clearable: "",
                    placeholder: "退货方式",
                    "popper-class": "select-idx",
                  },
                  model: {
                    value: _vm.queryForm.refund_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "refund_type", $$v)
                    },
                    expression: "queryForm.refund_type",
                  },
                },
                _vm._l(_vm.alist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    clearable: "",
                    placeholder: "业务员",
                    "popper-class": "select-idx",
                  },
                  model: {
                    value: _vm.queryForm.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "staff_id", $$v)
                    },
                    expression: "queryForm.staff_id",
                  },
                },
                _vm._l(_vm.blist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.user_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    clearable: "",
                    placeholder: "送货人",
                    "popper-class": "select-idx",
                  },
                  model: {
                    value: _vm.queryForm.deliver_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "deliver_id", $$v)
                    },
                    expression: "queryForm.deliver_id",
                  },
                },
                _vm._l(_vm.driverSelect, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "类型" },
                  model: {
                    value: _vm.queryForm.goods_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "goods_type", $$v)
                    },
                    expression: "queryForm.goods_type",
                  },
                },
                _vm._l(_vm.typeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                attrs: { "popper-class": "select-idx selectDC" },
                on: { "select-id": _vm.selectId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "brand" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                staticStyle: { width: "160px" },
                attrs: { "popper-class": "select-idx" },
                on: { "brand-select-change": _vm.chooseBrand },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品名称,简拼" },
                model: {
                  value: _vm.queryForm.keyword_goods,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "keyword_goods", $$v)
                  },
                  expression: "queryForm.keyword_goods",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "时间类型" },
                  model: {
                    value: _vm.queryForm.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "time_type", $$v)
                    },
                    expression: "queryForm.time_type",
                  },
                },
                _vm._l(_vm.timeTyleSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "popper-class": "select-idx",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.queryTable } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.list,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                                on: { change: _vm.filterChcekboxChange },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    width: "",
                    label: item.label,
                    prop: item.prop,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "数量"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.quantity) +
                                      _vm._s(row.unit_name) +
                                      " "
                                  ),
                                ])
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.pageNo,
              layout: _vm.layout,
              "page-size": _vm.queryForm.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }