import request from '@/utils/request'

// 表格数据
export function getList(data) {
  return request({
    url: '/saleAdmin/return-goods/index',
    method: 'post',
    data,
  })
}

// 搜索下拉
export function getSelect(data) {
  return request({
    url: '/saleAdmin/return-goods/option',
    method: 'post',
    data,
  })
}
/**
 * @导出退货列表
 */
export function exportQueryList(data) {
  return request({
    responseType: 'blob',
    url: '/saleAdmin/return-goods/export',
    method: 'post',
    data,
  })
}
