<template>
  <!-- NAME[epic=销售] 退货查询 -->
  <div v-loading="loading" class="orderTest-container">
    <el-form inline>
      <el-form-item>
        <el-select
          v-model="queryForm.refund_type"
          clearable
          placeholder="退货方式"
          :popper-class="'select-idx'"
          style="width: 140px"
        >
          <el-option
            v-for="item in alist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.staff_id"
          clearable
          placeholder="业务员"
          :popper-class="'select-idx'"
          style="width: 140px"
        >
          <el-option
            v-for="item in blist"
            :key="item.id"
            :label="item.user_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 送货人 -->
      <el-form-item>
        <el-select
          v-model="queryForm.deliver_id"
          clearable
          placeholder="送货人"
          :popper-class="'select-idx'"
          style="width: 140px"
        >
          <el-option
            v-for="item in driverSelect"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="queryForm.goods_type"
          clearable
          style="width: 140px"
          placeholder="类型"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <client-search
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
        ></client-search>
        <!-- <el-autocomplete
          v-model="queryForm.keyword"
          class="inline-input"
          :fetch-suggestions="querySearch"
          placeholder="客户名称、简拼"
          style="width: 170px"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button
          icon="el-icon-search"
          circle
          class="icon-btn"
          @click="iconClick"
        ></el-button> -->
      </el-form-item>
      <el-form-item prop="brand">
        <brand-select
          ref="brandSelect"
          style="width: 160px"
          :popper-class="'select-idx'"
          @brand-select-change="chooseBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          v-model="queryForm.keyword_goods"
          placeholder="商品名称,简拼"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="queryForm.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTyleSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          :popper-class="'select-idx'"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryTable">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <div class="table-wrapper">
      <!-- <div class="setFilter">
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </div> -->
      <el-table
        stripe
        :data="list"
        show-summary
        :summary-method="summaryFunction"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                  @change="filterChcekboxChange"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          align="center"
          width=""
          :label="item.label"
          :prop="item.prop"
        >
          <template #default="{ row }">
            <div v-if="item.label == '数量'">
              {{ row.quantity }}{{ row.unit_name }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          align="center"
          label="操作"
          fixed="right"
          show-overflow-tooltip
          width="190"
        >
          <template #default="{ $index, row }">
            <el-button type="text" @click="handleCheck($index, row)">
              查看
            </el-button>
            <el-button type="text" @click="handleReturnBack($index, row)">
              还货
            </el-button>
            <el-button type="text" @click="handleStop($index, row)">
              终止
            </el-button>
            <el-button type="text" @click="handlePrint($index, row)">
              打印
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="queryForm.pageNo"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getList, getSelect, exportQueryList } from '@/api/queryReturn'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { driverList } from '@/api/advanceOrder'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'QueryReturn',
    components: {
      ClientSearch,
      BrandSelect,
    },
    data() {
      return {
        loading: true,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        time: [],
        alist: [{}],
        blist: [{}],
        // 表格
        checkList: [
          '客户名称',
          '单号',
          '业务员',
          '送货人',
          '品牌',
          '商品名称',
          '退货价',
          '数量',
          '退货金额',
          '退货方式',
          '下单时间',
          '到货时间',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            width: '110',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 2,
            label: '单号',
            width: '150',
            prop: 'bill_code',
            sortable: false,
          },
          {
            order: 3,
            label: '业务员',
            width: '150',
            prop: 'staff_name',
            sortable: false,
          },
          {
            order: 3.5,
            label: '送货人',
            width: '150',
            prop: 'deliver_name',
            sortable: false,
          },
          {
            order: 3.6,
            label: '品牌',
            width: '150',
            prop: 'brand_name',
            sortable: false,
          },
          {
            order: 4,
            label: '商品名称',
            width: '100',
            prop: 'goods_name',
            sortable: false,
          },
          {
            order: 5,
            label: '退货价',
            width: '100',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            label: '数量',
            width: '90',
            prop: 'quantity',
            sortable: false,
          },
          {
            order: 7,
            label: '退货金额',
            width: '90',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 8,
            label: '退货方式',
            width: '100',
            prop: 'refund_type_text',
            sortable: false,
          },
          {
            order: 8.5,
            label: '下单时间',
            width: '100',
            prop: 'create_time',
            sortable: false,
          },
          {
            order: 8.6,
            label: '到货时间',
            width: '100',
            prop: 'arrive_time',
            sortable: false,
          },
          {
            order: 9,
            label: '备注',
            width: '100',
            prop: 'info',
            sortable: false,
          },
        ],
        list: [],
        selectList: {},
        typeSelect: [
          {
            id: 6,
            name: '退常品',
          },
          {
            id: 10,
            name: '退赠品',
          },
          {
            id: 13,
            name: '退尝品',
          },
          {
            id: 14,
            name: '预存退货',
          },
          {
            id: 9,
            name: '退回',
          },
        ],
        driverSelect: [],
        timeTyleSelect: [
          // 时间类型 1下单时间 2到货时间
          {
            id: 1,
            name: '下单时间',
          },
          {
            id: 2,
            name: '到货时间',
          },
        ],
        queryForm: {
          cust_id: '',
          pageNo: 1,
          pageSize: 10,
          refund_type: '', //退货方式
          staff_id: '', // 业务员ID
          deliver_id: '',
          keyword: '',
          keyword_goods: '',
          start_time: '',
          end_time: '',
          goods_type: '',
          time_type: 2,
          brand_id: '',
        },
        heji: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        console.log(val)
        if (val) {
          this.queryForm.start_time = val[0]
          this.queryForm.end_time = val[1]
        } else {
          this.queryForm.start_time = ''
          this.queryForm.end_time = ''
        }
      },
    },
    created() {
      this.getTableWidth()
      this.fetchData(this.queryForm)
      this.getSelectList()
      // 送货人下拉
      driverList().then((r) => {
        this.driverSelect = r.data
      })
    },
    activated() {
      this.fetchData(this.queryForm)
    },
    methods: {
      async fetchData(queryForm) {
        let { data, code, msg, totalCount } = await getList(queryForm)
        console.log('表格数据')
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
        this.heji = msg.replace('获取成功', ',').split(',')
      },
      async getSelectList() {
        let { data, code, msg } = await getSelect()
        console.log('下拉数据')
        this.alist = data.refund_type
        this.blist = data.staff
        console.log(data)
      },
      // async querySearch(val, cb) {
      //   let selectData = this.selectRes.data.client
      //   // let clientRes = await request({
      //   //   url: '/advance/clientSelect',
      //   //   method: 'get',
      //   // })
      //   // this.selectRes = clientRes.data.client
      //   console.log(selectData)
      //   var results = val
      //     ? selectData.filter(this.createStateFilter(val))
      //     : selectData
      //   cb(results)
      // },
      // createStateFilter(queryString) {
      //   return (state) => {
      //     return (
      //       state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      //     )
      //   }
      // },
      // setKeyWord(val) {
      //   this.queryForm.keyword = val
      // },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [9, 10]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            // if (idx == n) {
            //   console.log(col.property)
            //   const val = data.map((item) => Number(item[col.property]))
            //   console.log(val)
            //   if (!val.every((value) => isNaN(value))) {
            //     const a = val.reduce((total, num) => {
            //       if (!isNaN(total)) {
            //         return total + num
            //       }
            //     })
            //     sums[n] = a
            //   }
            // }
            sums[n] = this.heji[n - 9]
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.queryForm.cust_id = val
        } else {
          this.queryForm.cust_id = val
        }
      },
      clearInput() {
        this.queryForm.cust_id = ''
      },
      chooseBrand(val) {
        this.queryForm.brand_id = val
      },
      queryTable() {
        this.queryForm.pageSize = 10
        this.queryForm.pageNo = 1
        this.fetchData(this.queryForm)
      },
      // client btn click
      iconClick() {
        console.log('click search')
      },
      handleSelect(val, e) {
        console.log(val)
      },

      // 分页
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData(this.queryForm)
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData(this.queryForm)
      },
      handleExport() {
        exportQueryList(this.queryForm).then((res) => {
          let a = document.createElement('a')
          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          let url = URL.createObjectURL(blob)
          console.log('url', url)
          a.setAttribute('href', url)
          a.setAttribute('download', '退货列表.xlsx')
          a.click()
          URL.revokeObjectURL(url)
        })
      },
      // 获取一开始表格cloumns数据
      async getTableWidth() {
        let { data } = await postAction('/setAdmin/config/form-set-list', {
          form_type: 3,
        })
        console.log('data', data)
        let checkList = []
        data.forEach((i) => {
          if (i.width == 0) {
            i.width = ''
          }
          if (!i.hidden) {
            checkList.push(i.label)
          }
        })
        if (checkList.length == 0) {
          this.checkList = [
            '客户名称',
            '单号',
            '业务员',
            '送货人',
            '商品名称',
            '退货价',
            '数量',
            '退货金额',
            '退货方式',
            '下单时间',
            '到货时间',
            '备注',
          ]
        } else {
          this.checkList = checkList
        }
        if (data.length == 0) {
          this.columns = [
            {
              order: 1,
              label: '客户名称',
              width: '110',
              prop: 'cust_name',
              sortable: false,
            },
            {
              order: 2,
              label: '单号',
              width: '150',
              prop: 'bill_code',
              sortable: false,
            },
            {
              order: 3,
              label: '业务员',
              width: '150',
              prop: 'staff_name',
              sortable: false,
            },
            {
              order: 3.5,
              label: '送货人',
              width: '150',
              prop: 'deliver_name',
              sortable: false,
            },
            {
              order: 4,
              label: '商品名称',
              width: '100',
              prop: 'goods_name',
              sortable: false,
            },
            {
              order: 5,
              label: '退货价',
              width: '100',
              prop: 'goods_price',
              sortable: false,
            },
            {
              order: 6,
              label: '数量',
              width: '90',
              prop: 'quantity',
              sortable: false,
            },
            {
              order: 7,
              label: '退货金额',
              width: '90',
              prop: 'goods_money',
              sortable: false,
            },
            {
              order: 8,
              label: '退货方式',
              width: '100',
              prop: 'refund_type_text',
              sortable: false,
            },
            {
              order: 8.5,
              label: '下单时间',
              width: '100',
              prop: 'create_time',
              sortable: false,
            },
            {
              order: 8.6,
              label: '到货时间',
              width: '100',
              prop: 'arrive_time',
              sortable: false,
            },
            {
              order: 9,
              label: '备注',
              width: '100',
              prop: 'info',
              sortable: false,
            },
          ]
        } else {
          this.columns = data
        }
      },
      // 表格宽度发生变化
      async widthChange(newWidth, oldWidth, column, event) {
        console.log(newWidth, oldWidth, column, event)
        let a = this.columns.filter((i) => i.label == column.label)
        a[0].width = newWidth
        console.log('表格宽度发生变化', a)
        this.columns.forEach((i) => {
          if (i.label == column.label) {
            i.width = newWidth
          }
        })
        console.log('this.columns', this.columns)
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 3,
          form_data: JSON.stringify(this.columns),
        })
      },
      async filterChcekboxChange(v, io) {
        console.log('v', v)
        console.log('io', io)
        this.columns.forEach((i) => {
          if (i.label == io.target.value) {
            i.hidden = !v
          }
        })
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 3,
          form_data: JSON.stringify(this.columns),
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .icon-btn {
    position: absolute;
    top: 2px;
    right: 1px;
    border: 0px solid transparent;
  }
</style>
